/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                //// Contact Form
                $(document.body).click( function() {
                    $(".contactForm").removeClass("active");
                });

                $("#form_news_submit").on("submit", function(event) {
                    event.preventDefault();
                    var html = $("#base_url").html();
                    var value = $("#keywords").val();
                    window.location.href = html+value;
                });

                $(".contactForm").on("click", function (event) {
                    event.stopPropagation();
                    $(".contactForm").addClass("active");
                });

                //// Search Modal
                $('.menu-search').click(function(event){
                    event.preventDefault();
                    $('input[name="s"]').val('');
                    $('#searchModal').modal('toggle');
                });

                $('#searchModal').on('shown.bs.modal', function (e) {
                    $( "#searchModal .search-field" ).focus();
                });

                //Mobile Menu
                $('#mobile-nav-trigger').click(function(event){
                    event.preventDefault();
                    $('#mobileNavModal').modal('toggle');
                });

                var lastScrollTop = 0;
                $(window).bind('scroll', function () {
                  var default_max_pos = 500;
                  var default_min_pos = 255;

                  if($('body').hasClass('crisis')) {
                    default_max_pos = 700;
                  }

                  if($(window).width >= 768 && $(window).width < 992) {
                    default_min_pos = 300;
                  }

                  if ($(window).scrollTop() > default_min_pos && $(window).scrollTop() <= default_max_pos) {
                      $('#formFLoat').removeClass('stay-form');

                      var st = $(this).scrollTop();
                      if (st > lastScrollTop){
                        // downscroll code
                        $('#formFLoat').addClass('float-form');
                      } else {
                        if ($(window).scrollTop() > default_max_pos-30 && $(window).scrollTop() <= default_max_pos) {
                          $('#formFLoat').removeClass('float-form');
                        }
                        $('#formFLoat').addClass('float-form');
                        // upscroll code
                      }
                      lastScrollTop = st;
                  } else if($(window).scrollTop() > default_max_pos ) {
                      $('#formFLoat').removeClass('float-form');
                      $('#formFLoat').addClass('stay-form');
                  } else {
                      $('#formFLoat').removeClass('float-form');
                      $('#formFLoat').removeClass('stay-form');
                  }
                });

                $(document).ready(function(){
                    $('.carousel-banner.owl-carousel').owlCarousel({
                        loop:true,
                        items: 1,
                        autoplay:true,
                        autoplaySpeed: 500,
                        autoHeight : true
                    });

                    $('.owl-carousel').owlCarousel({
                        loop:true,
                        items: 1,
                        // items: 1,
                        // autoplay:true,
                        // autoplaySpeed: 500
                    });

                    $('.owl-carousel').on('changed.owl.carousel', function(e) {
                        $('.btn-play').css('display', 'block');
                        $('.btn-play').siblings("iframe").attr('src', '');
                    });

                    $('.btn-play').on('click', function(ev) {
                        ev.preventDefault();
                        var src = $(this).siblings("iframe").data('src');
                        $(this).siblings("iframe").attr('src', src);

                        var obj = this;

                        var timer3 = setTimeout((function() {
                            $(obj).css('display','none');
                            $(obj).siblings("iframe").css('display','block');
                        }),1000);
                    });

                });

                function set_min_height() {
                    var $window = $(window);
                    var windowsize = $window.width();
                    $('.content-service').each(function () {
                        var $columns = $('.card-box', this);
                        var maxHeight = Math.max.apply(Math, $columns.map(function () {
                            return $(this).height();
                        }).get());
                        // Set the height of all those children to whichever was highest
                        if (windowsize > 760) {
                            $columns.height(maxHeight);
                        } else {
                            $columns.height('');
                        }
                    });
                }
                set_min_height();
                $(window).on('resize', function () {
                    set_min_height();
                });

              var mytab = $('#myTab').length;

              if(mytab < 1) {
                $(document).on('click', 'a[href^="#"]', function (event) {
                  event.preventDefault();

                  $('html, body').animate({
                    scrollTop: $($.attr(this, 'href')).offset().top - 100,
                  }, 500);
                });
              }
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                $(function () {
                    $('[data-toggle="tooltip"]').tooltip()
                });

                var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

                if(iOS){
                    $("#hpVid").hide();
                }


                function calculate_height(){
                    var window_width = $(window).width();
                    if( window_width <= 767) {
                        var hero_height = $('.hero').outerHeight();
                        $("#big-video-wrap").css('height', hero_height);
                    } else {
                        $("#big-video-wrap").css('height', 730);
                    }
                };
                calculate_height();
                $(window).resize(function(){
                    calculate_height();
                });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the initç JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'page_template_template_locations': {
            init: function() {
                // JavaScript to be fired on the locations page
                $(".officeLocations__item").click(function() {
                    $('.officeLocations__item').removeClass('active');
                    $('.officeLocations__column').removeClass('active-column');
                    $(this).addClass('active');
                    $(this).parent().addClass('active-column');
                });

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

  var browsers = ["Opera", "Edg", "Chrome", "Safari", "Firefox", "MSIE", "Trident"];
  var userbrowser, useragent = navigator.userAgent;
  for (var i = 0; i < browsers.length; i++) {
    if( useragent.indexOf(browsers[i]) > -1 ) {
      userbrowser = browsers[i];
      break;
    }
  };

  switch(userbrowser) {
    case 'MSIE':
      userbrowser = 'Internet Explorer';
      break;

    case 'Trident':
      userbrowser = 'Internet Explorer';
      break;

    case 'Edg':
      userbrowser = 'Microsoft Edge';
      break;

    case 'Safari':
      userbrowser = 'safari';
      break;
  }

  $('.main').addClass(userbrowser);

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'macos';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'windows';
    } else if (/Android/.test(userAgent)) {
      os = 'android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'linux';
    }

    $('.main').addClass(os);
  }
  getOS();

})(jQuery); // Fully reference jQuery after this point.
